import React, { useState } from "react";
import { Breadcrum } from "../../components/Layout/breadcrum";
import { ScrollNav } from "../../components/ScrollNav";
import { SearchBox } from "../../components/SearchBox";
import PaginationComponent from "../../components/Pagination";
import { useNavigate } from "react-router-dom";
import CreditNoteData from "./BillingData/creditNoteData";

interface ICreditNoteProps {}

const CreditNote: React.FunctionComponent<ICreditNoteProps> = (props) => {
  const navigate = useNavigate();
  const [totalItemCount, setTotalItemCount] = useState(10);
  const [renderingComponents, setRenderingComponents] = useState(0);
  const arrayData = [
    { label: "Orders" },
    { label: "Invoice" },
    { label: "Credit Note" },
    { label: "Cod" },
  ];

  const render = (id: any) => {
    if (id === 0) {
      navigate("/billing/orders");
    } else if (id === 1) {
      navigate("/billing/invoices");
    } else if (id === 2) {
      navigate("/billing/credit-notes");
    } else if (id === 3) {
      navigate("/billing/cod");
    }
  };

  //on page change index
  const onPageIndexChange = () => {};

  // on per page item change
  const onPerPageItemChange = () => {};

  const setScrollIndex = (id: number) => {
    setRenderingComponents(id);
    render(id);
  };
  return (
    <>
      <div>
        <Breadcrum label="Billing" />
        <div className="customScroll">
          <div className="lg:flex justify-between mx-4 lg:mt-2 lg:mb-4">
            <div>
              <ScrollNav
                arrayData={arrayData}
                showNumber={false}
                setScrollIndex={setScrollIndex}
                defaultIndexValue={2}
              />
            </div>
            <div>
              <div>
                {/* <SearchBox label="Search" value="" onChange={() => {}} /> */}
              </div>
            </div>
          </div>
          <div className="mx-4">
            <CreditNoteData />
          </div>
        </div>

        {/* {totalItemCount > 0 && (
          <PaginationComponent
            totalItems={totalItemCount}
            itemsPerPageOptions={[
                      10, 20, 50, 100, 200, 500, 1000, 2000, 5000, 10000,
                    ]}
            onPageChange={onPageIndexChange}
            onItemsPerPageChange={onPerPageItemChange}
          />
        )} */}
      </div>
    </>
  );
};

export default CreditNote;
